export enum NotificationStatus {
  Info,
  Success,
  Warning,
  Error,
}

export interface Notification {
  id: string;
  message: string;
  status: NotificationStatus;
  showTimestamp?: boolean;
  shouldForceDuplicate?: boolean;
  shouldTimeout?: boolean;
  timestamp: string;
}
