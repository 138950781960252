import { PaginationRequestParams, PaginationRequestValue } from 'src/types/PaginationRequest';

export const TEMPORARY_PAGINATION_LIMIT = 100;
export const QUERY_PAGINATION_LIMIT = 10;
export const REACT_QUERY_DEFAULT_STALE_TIME = 2 * 60 * 1000;
export const RTK_QUERY_DEFAULT_STALE_TIME = 2 * 60;

export const DEFAULT_PAGINATION_ARGS: PaginationRequestParams = {
  [PaginationRequestValue.Offset]: 0,
  [PaginationRequestValue.Limit]: 100,
};
