import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import notificationsSlice, { NotificationPayload } from 'src/store/notifications';
import { NotificationStatus } from '../types';

const { showNotification: notificationFn, removeNotification: removeNotificationFn } =
  notificationsSlice.actions;

export function useNotification() {
  const dispatch = useDispatch();

  const showNotification = useCallback(
    (notification: NotificationPayload) => {
      dispatch(notificationFn(notification));
    },
    [dispatch],
  );

  const showSuccessNotification = useCallback(
    (message: string) => {
      const notification = {
        status: NotificationStatus.Success,
        message,
        shouldTimeout: true,
      };
      dispatch(notificationFn(notification));
    },
    [dispatch],
  );

  const showErrorNotification = useCallback(
    (message: string) => {
      const notification = {
        status: NotificationStatus.Error,
        message,
        showTimestamp: true,
      };
      dispatch(notificationFn(notification));
    },
    [dispatch],
  );

  const removeNotification = useCallback(
    (notificationId: string) => {
      dispatch(removeNotificationFn(notificationId));
    },
    [dispatch],
  );

  return {
    showNotification,
    showSuccessNotification,
    showErrorNotification,
    removeNotification,
  };
}
