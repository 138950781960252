// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v3.21.12
// source: common/stubs/site_manager/model/v1/node.proto

/* eslint-disable */
import { type Pipeline_PipelineStatus } from "../../../pipeline_manager/model/v1/pipeline";

export const protobufPackage = "v1.site_manager";

/** Next ID: 4 */
export enum NodeStatus {
  NS_DEFAULT = "NS_DEFAULT",
  NS_ACTIVE = "NS_ACTIVE",
  NS_DELETED = "NS_DELETED",
  NS_INACTIVE = "NS_INACTIVE",
}

export enum ProcessorType {
  /** PROCESSOR_TYPE_UNSPECIFIED - Undefined */
  PROCESSOR_TYPE_UNSPECIFIED = "PROCESSOR_TYPE_UNSPECIFIED",
  /** GENERIC_PREPROCESSOR - Needed */
  GENERIC_PREPROCESSOR = "GENERIC_PREPROCESSOR",
  /** CUSTOM_PREPROCESSOR - Needed */
  CUSTOM_PREPROCESSOR = "CUSTOM_PREPROCESSOR",
  /** GENERIC_POSTPROCESSOR - Needed */
  GENERIC_POSTPROCESSOR = "GENERIC_POSTPROCESSOR",
  /** CUSTOM_POSTPROCESSOR - Needed */
  CUSTOM_POSTPROCESSOR = "CUSTOM_POSTPROCESSOR",
  /** DATA_PROCESSOR - Needed */
  DATA_PROCESSOR = "DATA_PROCESSOR",
}

export enum TransformCategory {
  /** DEFAULT - Default category */
  DEFAULT = "DEFAULT",
  /** PARSER - Transforms that are used to Parse events */
  PARSER = "PARSER",
  /** SOURCE_CONFIG - Transforms that belong to the Source Configuration */
  SOURCE_CONFIG = "SOURCE_CONFIG",
  /** INTERNAL - Transforms that should not be visible to the user */
  INTERNAL = "INTERNAL",
  /** PATTERN_EXTRACTOR - Transforms which can be added to PE Pipeline */
  PATTERN_EXTRACTOR = "PATTERN_EXTRACTOR",
  /** FUNCTION - Transforms that are used to perform functions on events */
  FUNCTION = "FUNCTION",
  /** SERIALIZER - Transforms that are used to serialize events */
  SERIALIZER = "SERIALIZER",
  /** OPTIMIZER - Transforms that are used to group functions */
  OPTIMIZER = "OPTIMIZER",
  /** DEPRECATED - Transforms that are deprecated */
  DEPRECATED = "DEPRECATED",
}

export enum NodeTypes {
  NODE_TYPE_DEFAULT = "NODE_TYPE_DEFAULT",
  SOURCE = "SOURCE",
  SINK = "SINK",
  TRANSFORM = "TRANSFORM",
}

export enum NodeOrigin {
  NODE_ORIGIN_DEFAULT = "NODE_ORIGIN_DEFAULT",
  NODE_ORIGIN_USER = "NODE_ORIGIN_USER",
  NODE_ORIGIN_PATTERN = "NODE_ORIGIN_PATTERN",
  NODE_ORIGIN_ARCHIVAL = "NODE_ORIGIN_ARCHIVAL",
  NODE_ORIGIN_PREPROCESSOR = "NODE_ORIGIN_PREPROCESSOR",
  NODE_ORIGIN_POSTPROCESSOR = "NODE_ORIGIN_POSTPROCESSOR",
  NODE_ORIGIN_SSA = "NODE_ORIGIN_SSA",
}

export enum LogFormat {
  LOG_FORMAT_UNSPECIFIED = "LOG_FORMAT_UNSPECIFIED",
  CLOUDTRAIL = "CLOUDTRAIL",
  JSON = "JSON",
  RAW = "RAW",
  AWS_VPC_FLOWLOGS = "AWS_VPC_FLOWLOGS",
  GROK = "GROK",
  SYSLOG = "SYSLOG",
  CEF = "CEF",
  KEY_VALUE = "KEY_VALUE",
  AWS_CLOUDWATCH_SUBSCRIPTION = "AWS_CLOUDWATCH_SUBSCRIPTION",
}

export enum SSAAnalyticsType {
  SSA_ANALYTICS_DEFAULT = "SSA_ANALYTICS_DEFAULT",
  SSA_ANALYTICS_AWS_VPC_FLOW_LOGS = "SSA_ANALYTICS_AWS_VPC_FLOW_LOGS",
  SSA_ANALYTICS_GENERIC = "SSA_ANALYTICS_GENERIC",
  SSA_ANALYTICS_METRIC_GENERIC = "SSA_ANALYTICS_METRIC_GENERIC",
}

/** Next ID: 6 */
export interface SourceTemplate {
  id: string;
  type: SourceTemplate_SourceType;
  displayName: string;
  configFormat: { [key: string]: any } | undefined;
  version: string;
}

/** Next ID: 17 */
export enum SourceTemplate_SourceType {
  DEFAULT_TYPE = "DEFAULT_TYPE",
  DATADOG_AGENT = "DATADOG_AGENT",
  DEMO_LOGS = "DEMO_LOGS",
  AWS_S3 = "AWS_S3",
  FLUENT = "FLUENT",
  OPENTELEMETRY = "OPENTELEMETRY",
  SYSLOG = "SYSLOG",
  KAFKA = "KAFKA",
  AWS_KINESIS_FIREHOSE = "AWS_KINESIS_FIREHOSE",
  LOGSTASH = "LOGSTASH",
  HTTP = "HTTP",
  GCP_PUBSUB = "GCP_PUBSUB",
  SOCKET = "SOCKET",
  STDIN = "STDIN",
  PROMETHEUS_REMOTE_WRITE = "PROMETHEUS_REMOTE_WRITE",
  VECTOR = "VECTOR",
  SPLUNK_HEC = "SPLUNK_HEC",
  SPLUNK_S2S = "SPLUNK_S2S",
  GCP_GCS = "GCP_GCS",
  /** OC_SQLQUERY - Observo Collector Sources (OC) */
  OC_SQLQUERY = "OC_SQLQUERY",
  OC_HTTP = "OC_HTTP",
  OC_AZUREBLOB = "OC_AZUREBLOB",
}

/** Next ID: 25 */
export interface Source {
  id: string;
  siteId: string;
  templateId: string;
  templateVersion: string;
  templateName: string;
  name: string;
  description: string;
  config: { [key: string]: any } | undefined;
  status: NodeStatus;
  created: string | undefined;
  updated: string | undefined;
  createdBy: string;
  updatedBy: string;
  /** Derived from template */
  type: SourceTemplate_SourceType;
  origin: NodeOrigin;
  /**
   * archival_info holds the archival details on ListSources and ListNodes
   * requests if the source is archival enabled and it's archival pipeline
   * deployed Otherwise, the value of archival_info will be nil
   */
  archivalInfo:
    | ArchivalInfo
    | undefined;
  /** TODO: Deprecate this */
  logFormat: LogFormat;
  preprocessorConfig: { [key: string]: any } | undefined;
  port: number;
  /** push_based refers if the source is push based or not */
  pushBased: boolean;
  /** push_source_address is where the push source events are sent */
  pushSourceAddress: string;
  /** push_source_address is where the push source events are sent */
  k8sInternalSvcUrl: string;
  /** Site files linked to this source. */
  siteFilenames: string[];
  /** SourceConfigs are the configs for the source. */
  sourceConfigs: SourceConfig[];
}

/** Next ID: 6 */
export interface SinkTemplate {
  id: string;
  type: SinkTemplate_SinkType;
  displayName: string;
  configFormat: { [key: string]: any } | undefined;
  isArchival: boolean;
  version: string;
}

/** Next ID: 21 */
export enum SinkTemplate_SinkType {
  /**
   * DEFAULT_TYPE - To be treated as null. Defaults value of enum is set to this if not
   * explicitly assigned.
   */
  DEFAULT_TYPE = "DEFAULT_TYPE",
  AWS_S3 = "AWS_S3",
  FILE = "FILE",
  HTTP = "HTTP",
  BLACKHOLE = "BLACKHOLE",
  ELASTICSEARCH = "ELASTICSEARCH",
  KAFKA = "KAFKA",
  SOCKET = "SOCKET",
  SPLUNK_HEC_LOGS = "SPLUNK_HEC_LOGS",
  LOKI = "LOKI",
  DATADOG_EVENTS = "DATADOG_EVENTS",
  DATADOG_LOGS = "DATADOG_LOGS",
  DATADOG_METRICS = "DATADOG_METRICS",
  DATADOG_TRACES = "DATADOG_TRACES",
  GCP_CLOUD_STORAGE = "GCP_CLOUD_STORAGE",
  NEW_RELIC = "NEW_RELIC",
  AZURE_MONITOR_LOGS = "AZURE_MONITOR_LOGS",
  AZURE_SENTINEL_LOGS = "AZURE_SENTINEL_LOGS",
  GCP_CHRONICLE_UNSTRUCTURED = "GCP_CHRONICLE_UNSTRUCTURED",
  AZURE_BLOB = "AZURE_BLOB",
  SPLUNK_HEC_METRICS = "SPLUNK_HEC_METRICS",
  PROMETHEUS_EXPORTER = "PROMETHEUS_EXPORTER",
  PROMETHEUS_REMOTE_WRITE = "PROMETHEUS_REMOTE_WRITE",
}

/** Next ID: 20 */
export interface Sink {
  id: string;
  siteId: string;
  templateId: string;
  templateVersion: string;
  templateName: string;
  name: string;
  description: string;
  config: { [key: string]: any } | undefined;
  status: NodeStatus;
  sourceId: string;
  created: string | undefined;
  updated: string | undefined;
  createdBy: string;
  updatedBy: string;
  /** Derived from template */
  type: SinkTemplate_SinkType;
  origin: NodeOrigin;
  usageType: Sink_UsageType;
  /** Site files linked to this sink. */
  siteFilenames: string[];
}

export enum Sink_UsageType {
  UNSPECIFIED = "UNSPECIFIED",
  USER = "USER",
  ARCHIVAL = "ARCHIVAL",
  PATTERN_EXTRACTOR = "PATTERN_EXTRACTOR",
  SSA = "SSA",
}

/** Next ID: 12 */
export interface TransformTemplate {
  id: string;
  name: string;
  processorType: ProcessorType;
  configFormat: { [key: string]: any } | undefined;
  isTransformGroup: boolean;
  description: string;
  created: string | undefined;
  updated: string | undefined;
  displayName: string;
  /** parsers, filters, aggregators etc. */
  category: TransformCategory;
  version: string;
}

/** Next ID: 19 */
export interface Transform {
  id: string;
  siteId: string;
  templateId: string;
  templateVersion: string;
  name: string;
  description: string;
  pipelineId: string;
  config: { [key: string]: any } | undefined;
  status: NodeStatus;
  created: string | undefined;
  updated: string | undefined;
  createdBy: string;
  updatedBy: string;
  /** Derived from template */
  isTransformGroup: boolean;
  origin: NodeOrigin;
  templateName: string;
  processorType: ProcessorType;
  /** Site files linked to this transform. */
  siteFilenames: string[];
}

/** Next ID: 4 */
export interface Node {
  source?: Source | undefined;
  transform?: Transform | undefined;
  sink?: Sink | undefined;
}

/** Next ID: 7 */
export interface ArchivalInfo {
  sinkId: string;
  sinkName: string;
  sinkType: SinkTemplate_SinkType;
  pipelineId: string;
  pipelineName: string;
  pipelineStatus: Pipeline_PipelineStatus;
}

export interface LogFormatTemplate {
  logFormat: LogFormat;
  transformTemplateName: string;
  showTemplate: boolean;
}

export interface SourceConfig {
  id: string;
  sourceId: string;
  templateId: string;
  templateName: string;
  category: TransformCategory;
  config:
    | { [key: string]: any }
    | undefined;
  /** Determines the position of the SourceConfig - relevant */
  position: number;
}

export interface SourceLogFormat {
  sourceId: string;
  sourceLogFormat: LogFormat;
  ssaType: SSAAnalyticsType[];
  patternExtractorEnabled: boolean;
}
