import { createIcon } from '@chakra-ui/react';

export const ObservoLogoNoText = createIcon({
  displayName: 'ObservoLogoNoText',
  viewBox: '0 0 37 32',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8506 12.8719L0 3.00391V20.4693L18.8506 30.3372V12.8719ZM11.9204 18.6027C13.3065 18.6027 14.4301 17.5115 14.4301 16.1655C14.4301 14.8194 13.3065 13.7282 11.9204 13.7282C10.5344 13.7282 9.41077 14.8194 9.41077 16.1655C9.41077 17.5115 10.5344 18.6027 11.9204 18.6027Z"
      fill="#3DA2FF"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8506 12.8719L37.7012 3.00391V20.4693L18.8506 30.3372V12.8719ZM25.724 18.6027C27.1101 18.6027 28.2337 17.5115 28.2337 16.1655C28.2337 14.8194 27.1101 13.7282 25.724 13.7282C24.338 13.7282 23.2144 14.8194 23.2144 16.1655C23.2144 17.5115 24.338 18.6027 25.724 18.6027Z"
      fill="#62B3FF"
    />,
  ],
});
