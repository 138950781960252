import { ErrorReason } from '@common-types/common/errors/error_reasons';
import { LOGIN_ERROR_MODAL_TEXTS } from 'src/constants/errors';
import AppErrorModal from '../AppErrorModal/AppErrorModal';

function UnauthorizedPage({ error }: { error: string }) {
  const isUnauthorized = error === ErrorReason.UNAUTHORIZED;
  const isForbidden = error === ErrorReason.FORBIDDEN;

  const props = isUnauthorized
    ? LOGIN_ERROR_MODAL_TEXTS.NOT_AUTHORIZED
    : LOGIN_ERROR_MODAL_TEXTS.FORBIDDEN;

  return <AppErrorModal {...props} showReload={isForbidden} />;
}
export default UnauthorizedPage;
