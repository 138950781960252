import { AddSourceResponse } from '@common-types/site_manager/api/v1/site_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'src/components/Notification/hooks/useNotification';
import axios from 'src/lib/axios/axios';
import { AddSourceMutationRequest } from 'src/types/Source';

export function useAddSourceMutation() {
  const queryClient = useQueryClient();
  const { showSuccessNotification } = useNotification();

  return useMutation<{ data: AddSourceResponse }, unknown, AddSourceMutationRequest>({
    mutationFn: (data) => axios.post('/v1/source', data),
    onSuccess: async () => {
      showSuccessNotification('Source added successfully.');
      await queryClient.invalidateQueries({ queryKey: ['sources'] });
      await queryClient.invalidateQueries({ queryKey: ['list-sources'] });
      await queryClient.invalidateQueries({ queryKey: ['node-names'] });
    },
  });
}
