import { differenceInSeconds } from 'date-fns';
import {
  DATE_FILTER_START_DATE,
  DATE_FILTER_VALUES,
  DEFAULT_DATE_FILTER_VALUE,
} from 'src/constants/dateFilter';
import { TIME_IN_SECONDS } from 'src/constants/time';
import { CUSTOM_DATE_FILTER, DateFilter } from 'src/types/DateFilter';
import { convertDateToUnixTimeSeconds } from './date';

export const CUSTOM_DATE_DIFF_THRESHOLD = 60;
export const CUSTOM_DATE_SEP = ';';

export function getCustomDateFilterValue(timeValue: string): [string, string] | undefined {
  if (timeValue.startsWith(CUSTOM_DATE_FILTER)) {
    const dateValueSplits = timeValue.split(CUSTOM_DATE_SEP);
    const startDateString = dateValueSplits[1];
    const endDateString = dateValueSplits[2];
    const diff = differenceInSeconds(new Date(endDateString), new Date(startDateString));
    if (diff > 0 && diff <= CUSTOM_DATE_DIFF_THRESHOLD * TIME_IN_SECONDS.ONE_DAY) {
      return [startDateString, endDateString];
    }
  }
}

export function getSelectedDateFilterDisplayName(selectedValue: string): string | undefined {
  if (getCustomDateFilterValue(selectedValue)) {
    return 'Custom';
  }
  const optionValue = DATE_FILTER_VALUES.find((dateFilter) => dateFilter.id === selectedValue);
  if (optionValue) {
    return optionValue.displayName;
  }
}

function getTimeFrameResolution(startDate: Date, endDate: Date): number {
  const diff = differenceInSeconds(endDate, startDate);
  if (diff <= TIME_IN_SECONDS.ONE_MINUTE * 15) {
    return TIME_IN_SECONDS.ONE_MINUTE;
  } else if (diff <= TIME_IN_SECONDS.ONE_HOUR) {
    return TIME_IN_SECONDS.ONE_MINUTE * 5;
  } else if (diff <= TIME_IN_SECONDS.ONE_HOUR * 4) {
    return TIME_IN_SECONDS.ONE_MINUTE * 15;
  } else if (diff <= TIME_IN_SECONDS.ONE_HOUR * 12) {
    return TIME_IN_SECONDS.ONE_HOUR;
  } else if (diff <= TIME_IN_SECONDS.ONE_DAY * 2) {
    return TIME_IN_SECONDS.ONE_HOUR * 2;
  } else if (diff <= TIME_IN_SECONDS.ONE_DAY * 8) {
    return TIME_IN_SECONDS.ONE_HOUR * 4;
  } else {
    return TIME_IN_SECONDS.ONE_HOUR * 6;
  }
}

function getDateFilterValues(dateFilterValue: string): { start: Date; end: Date } {
  const dateValue = dateFilterValue || DEFAULT_DATE_FILTER_VALUE;
  const dateStrings = getCustomDateFilterValue(dateValue);
  if (dateStrings) {
    return { start: new Date(dateStrings[0]), end: new Date(dateStrings[1]) };
  }
  const dateFilterKey = dateValue in DATE_FILTER_START_DATE ? dateValue : DEFAULT_DATE_FILTER_VALUE;
  const endDate = new Date();
  return { start: DATE_FILTER_START_DATE[dateFilterKey as DateFilter](), end: endDate };
}

export function getTimeQueryValues(timeValue: string) {
  const dateValues = getDateFilterValues(timeValue);
  return {
    startDateUnixTime: convertDateToUnixTimeSeconds(dateValues.start),
    endDateUnixTime: convertDateToUnixTimeSeconds(dateValues.end),
    resolutionInSeconds: getTimeFrameResolution(dateValues.start, dateValues.end),
  };
}
