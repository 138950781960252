import { UpdateSinkResponse } from '@common-types/site_manager/api/v1/site_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'src/components/Notification/hooks/useNotification';
import axios from 'src/lib/axios/axios';
import { UpdateSinkMutationRequest } from 'src/types/Destination';

export function useUpdateSinkMutation() {
  const queryClient = useQueryClient();
  const { showSuccessNotification } = useNotification();

  return useMutation<{ data: UpdateSinkResponse }, unknown, UpdateSinkMutationRequest>({
    mutationFn: (data) => axios.patch('/v1/sink', data),
    onSuccess: async () => {
      showSuccessNotification('Destination updated successfully.');
      await queryClient.invalidateQueries({ queryKey: ['sinks'] });
      await queryClient.invalidateQueries({ queryKey: ['list-sinks'] });
      await queryClient.invalidateQueries({ queryKey: ['node-names'] });
      await queryClient.invalidateQueries({ queryKey: ['sources'] });
    },
  });
}
