export enum DateFilter {
  Last5Mins = '5min',
  Last15Mins = '15min',
  Last1Hour = '1h',
  Last4Hours = '4h',
  Last12Hours = '12h',
  Last1Day = '1d',
  Last7Days = '7d',
  Last60Days = '60d',
}

export const CUSTOM_DATE_FILTER = 'custom';
