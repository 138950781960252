import { useSearchParams } from 'react-router';
import { useSite } from 'src/context/SiteProvider';
import { useNotificationsPoller } from './useNotificationsPoller';
import useOnMountEffect from './useOnMountEffect';

export const useAppInit = () => {
  const { setSelectedSiteId } = useSite();
  const [searchParams] = useSearchParams();

  useOnMountEffect(() => {
    const site = searchParams.get('site');
    if (site) {
      localStorage.setItem('siteId', site);
      setSelectedSiteId(site);
    }
  });
  useNotificationsPoller();
};
