import { convertUnixTimeSecondsToISOFormat } from './date';
import { getTimeQueryValues } from './getDefaultFilterValues';

export function getApiTimeRangeSeconds(timeValue: string, isResolutionRequired = false) {
  const timeQuery = getTimeQueryValues(timeValue);
  if (isResolutionRequired) {
    return {
      'time_range.start_timestamp.seconds': timeQuery.startDateUnixTime,
      'time_range.end_timestamp.seconds': timeQuery.endDateUnixTime,
      'resolution.seconds': timeQuery.resolutionInSeconds,
    };
  } else {
    return {
      'time_range.start_timestamp.seconds': timeQuery.startDateUnixTime,
      'time_range.end_timestamp.seconds': timeQuery.endDateUnixTime,
    };
  }
}

export function getApiTimeRangeTimeStamp(timeValue: string) {
  const timeQuery = getTimeQueryValues(timeValue);

  return {
    time_range: {
      start_timestamp: convertUnixTimeSecondsToISOFormat(timeQuery.startDateUnixTime),
      end_timestamp: convertUnixTimeSecondsToISOFormat(timeQuery.endDateUnixTime),
    },
  };
}

export function getApiTimestampSeconds(timeValue: string) {
  const timeQuery = getTimeQueryValues(timeValue);
  return {
    'fromTimestamp.seconds': timeQuery.startDateUnixTime,
    'toTimestamp.seconds': timeQuery.endDateUnixTime,
  };
}

export function getApiHydrationTimestamp(timeValue: string) {
  const timeQuery = getTimeQueryValues(timeValue);
  return {
    'createdAt.startTimestamp.seconds': timeQuery.startDateUnixTime,
    'createdAt.endTimestamp.seconds': timeQuery.endDateUnixTime,
  };
}
