import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { SelectedFilterValues } from 'src/components/Filters/types';
import { DEFAULT_DATE_FILTER_VALUE } from 'src/constants/dateFilter';
import { ROUTE_PATHS } from 'src/constants/routes';
import { useGetTimeFilterValue } from 'src/hooks/useGetTimeFilterValue';

interface TimeFilterContextType {
  timeValue: string;
  selectedFilterValues: SelectedFilterValues;
  currentDateFilterDisplayName: string | undefined;
  handleFilterChange: (filterName: string, filterValue: string[]) => void;
  canResetTimeFilter: boolean;
  resetTimeFilter: () => void;
}

const TimeFilterContext = createContext({
  timeValue: DEFAULT_DATE_FILTER_VALUE,
} as TimeFilterContextType);

export const TIME_FILTER_PAGES = [
  ROUTE_PATHS.dashboard,
  ROUTE_PATHS.hydration,
  ROUTE_PATHS.root,
  ROUTE_PATHS.pipelines.index,
  ROUTE_PATHS.pipelines.pipelineDetails,
  ROUTE_PATHS.sources.index,
  ROUTE_PATHS.destinations,
  ROUTE_PATHS.monitoring.analytics,
  ROUTE_PATHS.monitoring.pipelineErrors,
  ROUTE_PATHS.notifications,
  ROUTE_PATHS.events.notifications,
  ROUTE_PATHS.alerts,
];

export function useTimeFilter() {
  return useContext(TimeFilterContext);
}

export const TimeFilterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    selectedFilterValues,
    handleFilterChange,
    timeValue,
    currentDateFilterDisplayName,
    canResetTimeFilter,
    resetTimeFilter,
  } = useGetTimeFilterValue();

  const value = useMemo(
    () => ({
      timeValue,
      handleFilterChange,
      selectedFilterValues,
      currentDateFilterDisplayName,
      canResetTimeFilter,
      resetTimeFilter,
    }),
    [
      timeValue,
      selectedFilterValues,
      currentDateFilterDisplayName,
      handleFilterChange,
      canResetTimeFilter,
      resetTimeFilter,
    ],
  );

  return <TimeFilterContext.Provider value={value}>{children}</TimeFilterContext.Provider>;
};
