import { useCallback, useEffect } from 'react';
import useFilter from 'src/components/Filters/hooks/useFilter';
import { FilterStorageType } from 'src/components/Filters/types';
import { APP_DEFAULT_FILTER_VALUES, LOCAL_STORAGE_FILTER_TIMESTAMP } from 'src/constants';
import { DEFAULT_DATE_FILTER_VALUE } from 'src/constants/dateFilter';
import { FilterViewName } from 'src/constants/filters';
import { getSelectedDateFilterDisplayName } from 'src/utils/getDefaultFilterValues';
import { useDerivedStateFromProp } from './useDerivedStateFromProp';
import useOnMountEffect from './useOnMountEffect';

export function useGetTimeFilterValue() {
  const {
    onChange: onSelectedFilterValuesChange,
    selectedFilterValues,
    setSelectedFilterValues,
  } = useFilter(
    APP_DEFAULT_FILTER_VALUES,
    FilterViewName.TimeFilter,
    FilterStorageType.LocalStorage,
  );

  const currentDateFilterDateValue = selectedFilterValues.date[0];
  const currentDateFilterDisplayName = getSelectedDateFilterDisplayName(currentDateFilterDateValue);
  const canResetTimeFilter = currentDateFilterDateValue !== DEFAULT_DATE_FILTER_VALUE;

  const [timeValue, setTimeValue] = useDerivedStateFromProp(currentDateFilterDateValue);

  const resetTimeFilter = useCallback(() => {
    setSelectedFilterValues(APP_DEFAULT_FILTER_VALUES);
    setTimeValue(DEFAULT_DATE_FILTER_VALUE);
  }, [setSelectedFilterValues, setTimeValue]);

  useEffect(() => {
    if (!currentDateFilterDisplayName) {
      resetTimeFilter();
    }
  }, [currentDateFilterDisplayName, resetTimeFilter]);

  useOnMountEffect(() => {
    const localStorageFilterTimestamp = localStorage.getItem('localStorageFilterTimestamp');
    if (localStorageFilterTimestamp !== LOCAL_STORAGE_FILTER_TIMESTAMP) {
      localStorage.setItem('localStorageFilterTimestamp', LOCAL_STORAGE_FILTER_TIMESTAMP);
      resetTimeFilter();
    }
  });

  return {
    timeValue,
    handleFilterChange: onSelectedFilterValuesChange,
    selectedFilterValues,
    currentDateFilterDisplayName,
    canResetTimeFilter,
    resetTimeFilter,
  };
}
