import { format } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import formatRelative from 'date-fns/formatRelative';
import parseISO from 'date-fns/parseISO';
import capitalize from 'lodash/capitalize';
import {
  DATE_FILTER_FORMAT_STRING,
  HUMAN_READABLE_DATE_TIME_FORMAT,
} from 'src/constants/dateFilter';

export function convertUnixTimeSecondsToFormattedDateString(
  unixTimeSeconds: string,
  dateFormat = 'MMM dd',
) {
  const date = new Date(Number(unixTimeSeconds) * 1000);
  const formattedDateString = format(date, dateFormat);

  return formattedDateString;
}

export function convertDateToUnixTimeSeconds(date: Date) {
  return Math.round(date.getTime() / 1000);
}

export function convertUnixTimeSecondsToISOFormat(seconds: number) {
  return new Date(seconds * 1000).toISOString();
}

export function convertISOFormatToUnixTimeSeconds(timestamp: string) {
  return convertDateToUnixTimeSeconds(parseISO(timestamp));
}

export function startOfDayInUtc(inputDate: Date): Date {
  const currentUtcDateString = formatInTimeZone(inputDate, 'UTC', 'EEE MMM dd yyyy 00:00:00');
  const currentStartOfDayInUtc = new Date(`${currentUtcDateString} GMT`);
  return currentStartOfDayInUtc;
}

function isValidDate(date?: Date): date is Date {
  return date instanceof Date && !isNaN(Number(date));
}

export function humanReadableDateString(date?: Date): string {
  return isValidDate(date) ? format(date, HUMAN_READABLE_DATE_TIME_FORMAT) : '';
}

export function formatDateString({
  dateString,
  dateFormat = DATE_FILTER_FORMAT_STRING,
  defaultOutput = '',
}: {
  dateString?: string;
  dateFormat?: string;
  defaultOutput?: string;
}): string {
  if (!dateString) {
    return defaultOutput;
  }

  const date = new Date(dateString);

  if (!isValidDate(date)) {
    return defaultOutput;
  }

  return format(date, dateFormat);
}

export const getCreatedAtRelativeTime = (timestamp: string) =>
  capitalize(
    formatRelative(parseISO(timestamp), new Date(), {
      weekStartsOn: 1,
    }),
  );
