import { InfoIcon, WarningIcon } from '@chakra-ui/icons';
import { COLORS } from 'src/constants/colors';
import { ErrorCircleIcon } from '../Icons/ErrorCircleIcon';
import { SuccessCircleIcon } from '../Icons/SuccessCircleIcon';
import { NotificationStatus } from './types';

export const INITIAL_ANIMATION_CONFIG = { opacity: 0, scale: 0, x: -300 };

export const EXIT_ANIMATION_CONFIG = { opacity: 0, scale: 0 };

export const MOUNT_ANIMATION_CONFIG = { opacity: 1, scale: 1, x: 0 };

export const UPDATE_ANIMATION_CONFIG = { x: [0, -30, 30, 0] };

export const STATUS_COLOR_MAP = {
  [NotificationStatus.Info]: [COLORS.notificationStatus.info, COLORS.link],
  [NotificationStatus.Success]: [COLORS.notificationStatus.success, COLORS.status.success],
  [NotificationStatus.Warning]: [COLORS.notificationStatus.warning, COLORS.status.warning],
  [NotificationStatus.Error]: [COLORS.notificationStatus.error, COLORS.status.error],
};

export const STATUS_ICON_MAP = {
  [NotificationStatus.Info]: InfoIcon,
  [NotificationStatus.Success]: SuccessCircleIcon,
  [NotificationStatus.Warning]: WarningIcon,
  [NotificationStatus.Error]: ErrorCircleIcon,
};
