import { ReactElement, useEffect } from 'react';
import { Popover } from '@chakra-ui/react';
import { isNonEmptyArray } from 'src/utils/isNonEmptyArray';
import { useAutocompleteContext } from './AutocompleteContext';
import { AutocompleteOptions } from './types';

export interface AutocompleteProps {
  children: ReactElement;
  options: AutocompleteOptions;
  restrictValueFromOptionsOnly?: boolean;
  matchPopoverWidthWithInput?: boolean;
  showOptionsOnSearchTextOnly?: boolean;
}

export function AutocompleteBox({
  children,
  options,
  restrictValueFromOptionsOnly = false,
  matchPopoverWidthWithInput = true,
}: AutocompleteProps) {
  const { isOpen, inputRef, setAutocompleteOptions, setIsRestrictedValue } =
    useAutocompleteContext();

  useEffect(() => {
    if (isNonEmptyArray(options)) {
      setAutocompleteOptions(options);
    }
  }, [options, setAutocompleteOptions]);

  useEffect(() => {
    setIsRestrictedValue(restrictValueFromOptionsOnly);
  }, [restrictValueFromOptionsOnly, setIsRestrictedValue]);

  return (
    <Popover
      matchWidth={matchPopoverWidthWithInput}
      isLazy
      isOpen={isOpen}
      initialFocusRef={inputRef}
    >
      {children}
    </Popover>
  );
}
