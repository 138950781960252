import { Divider, Flex, Icon, Spacer, Stack } from '@chakra-ui/react';
import { NavLink as ReactRouterNavLink } from 'react-router';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { ROUTE_PATHS } from 'src/constants/routes';
import { ALWAYS_VISIBLE_SCROLLBAR_STYLES } from 'src/constants/scrollbar';
import { useSite } from 'src/context/SiteProvider';
import {
  isGitOpsFeatureEnabled,
  isLogSearchFeatureEnabled,
  isShowAlertEventsPageEnabled,
  isUserReportsFeatureEnabled,
} from 'src/utils/featureFlags';
import { isSiteEverUsed } from 'src/utils/site';
import { AlertsIcon } from '../Icons/AlertsIcon';
import { BellIcon } from '../Icons/BellIcon';
import { DashboardIcon } from '../Icons/DashboardIcon';
import { DestinationIcon } from '../Icons/DestinationIcon';
import { FileIcon } from '../Icons/FileIcon';
import { HydrationIcon } from '../Icons/HydrationIcon';
import { LogSearchIcon } from '../Icons/LogSearchIcon';
import { MonitoringIcon } from '../Icons/MonitoringIcon';
import { ObservoLogo } from '../Icons/ObservoLogo';
import { PipelinesIcon } from '../Icons/PipelinesIcon';
import { SettingsSidebarIcon } from '../Icons/SettingsSidebarIcon';
import { SourceIcon } from '../Icons/SourceIcon';
import { SiteSelector } from '../SiteSelector/SiteSelector';
import { AlertCount } from './parts/AlertCount';
import { ErrorIndicator } from './parts/ErrorIndicator';
import { NavLink } from './parts/NavLink';
import { NotificationCount } from './parts/NotificationCount';
import { UserProfile } from './parts/UserProfile';

enum SidebarNavLinkLabel {
  Dashboard = 'Dashboard',
  Pipelines = 'Pipelines',
  Settings = 'Settings',
  Sources = 'Sources',
  Destinations = 'Destinations',
  Hydration = 'Hydration',
  LogSearch = 'Log Search',
  Monitoring = 'Monitoring',
  Alerts = 'Alerts',
  Reports = 'Reports',
}

export const Sidebar: React.FC = () => {
  const { site } = useSite();

  const shouldShowNavItems = isSiteEverUsed(site);

  return (
    <Flex
      height="100vh"
      width="268px"
      minWidth="268px"
      direction="column"
      borderRightWidth="1px"
      py={8}
      px={1}
      borderColor={rgba(COLORS.lightVividSkyBlue, OPACITY[15])}
    >
      <Stack px={3}>
        <Flex flexDir="column" alignItems="center" justifyContent="center" mb={6} gap={2}>
          <ReactRouterNavLink to={ROUTE_PATHS.root}>
            <Icon w="160px" h="auto" as={ObservoLogo} />
          </ReactRouterNavLink>
        </Flex>
        <SiteSelector />
        {shouldShowNavItems && (
          <NavLink
            label="Notifications"
            icon={BellIcon}
            my={2}
            to={ROUTE_PATHS.notifications}
            rightContent={<NotificationCount />}
          />
        )}

        <Divider width="full" />
      </Stack>
      <Flex
        px={3}
        direction="column"
        overflowY="auto"
        flexGrow={1}
        css={ALWAYS_VISIBLE_SCROLLBAR_STYLES}
      >
        <Stack spacing={6} mt={6}>
          <Stack>
            {shouldShowNavItems && (
              <NavLink
                label={SidebarNavLinkLabel.Dashboard}
                icon={DashboardIcon}
                to={ROUTE_PATHS.root}
              />
            )}

            <NavLink
              label={SidebarNavLinkLabel.Pipelines}
              icon={PipelinesIcon}
              to={ROUTE_PATHS.pipelines.index}
            />
            {shouldShowNavItems && (
              <>
                <NavLink
                  label={SidebarNavLinkLabel.Sources}
                  icon={SourceIcon}
                  to={ROUTE_PATHS.sources.index}
                />
                <NavLink
                  label={SidebarNavLinkLabel.Destinations}
                  icon={DestinationIcon}
                  to={ROUTE_PATHS.destinations}
                />
                <NavLink
                  label={SidebarNavLinkLabel.Hydration}
                  icon={HydrationIcon}
                  to={ROUTE_PATHS.hydration}
                />
                {isLogSearchFeatureEnabled() && (
                  <NavLink
                    label={SidebarNavLinkLabel.LogSearch}
                    icon={LogSearchIcon}
                    to={ROUTE_PATHS.logSearch}
                  />
                )}
                <NavLink
                  data-cy="monitoring-error-indicator"
                  label={SidebarNavLinkLabel.Monitoring}
                  icon={MonitoringIcon}
                  to={ROUTE_PATHS.monitoring.index}
                  rightContent={<ErrorIndicator />}
                />
                {isShowAlertEventsPageEnabled() && (
                  <NavLink
                    data-cy="alert-events-sidebar-link"
                    label={SidebarNavLinkLabel.Alerts}
                    icon={AlertsIcon}
                    to={ROUTE_PATHS.alerts}
                    rightContent={<AlertCount />}
                  />
                )}
                {isUserReportsFeatureEnabled() && (
                  <NavLink
                    label={SidebarNavLinkLabel.Reports}
                    icon={FileIcon}
                    to={ROUTE_PATHS.reports}
                  />
                )}
                {isGitOpsFeatureEnabled() && (
                  <NavLink label="Events" icon={BellIcon} my={2} to={ROUTE_PATHS.events.index} />
                )}
              </>
            )}
          </Stack>
        </Stack>
        <Spacer />
        <Stack mb={6} mt={6}>
          <NavLink
            label={SidebarNavLinkLabel.Settings}
            icon={SettingsSidebarIcon}
            to={ROUTE_PATHS.settings.index}
            iconActiveColor={COLORS.fullWhite}
            iconInactiveColor={COLORS.inactiveWhiteIcon}
          />
        </Stack>
        <UserProfile />
      </Flex>
    </Flex>
  );
};
