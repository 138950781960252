import {
  GetPipelinesErrorCountResponse,
  AcknowledgeErrorsRequest,
} from '@common-types/analytics_service/api/v1/analytics_service';
import { AcknowledgementState } from '@common-types/analytics_service/conf/v1/analytics-config';
import apiSlice from 'src/store/api';
import { RTK_QUERY_DEFAULT_STALE_TIME } from 'src/constants/queries';
import { getApiTimeRangeSeconds } from 'src/utils/apiTimeRangeParams';
import { MAX_DATE_FILTER_VALUE } from 'src/constants/dateFilter';

interface PipelineErrorCountParams {
  siteId: string;
  acknowledgeState: AcknowledgementState;
}

const API_TAG_PIPELINE_ERROR_COUNT = 'monitoringPipelineErrorCount';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: [API_TAG_PIPELINE_ERROR_COUNT] });

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    pipelineErrorCount: build.query<GetPipelinesErrorCountResponse, PipelineErrorCountParams>({
      query: ({ siteId, acknowledgeState }) => ({
        url: '/v1/analytics-service/pipelines/errors/count',
        params: {
          siteId,
          acknowledgeState,
          ...getApiTimeRangeSeconds(MAX_DATE_FILTER_VALUE),
        },
      }),
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_PIPELINE_ERROR_COUNT],
    }),
    ackUnreadErrors: build.mutation<unknown, AcknowledgeErrorsRequest>({
      query: (data) => ({
        url: '/v1/analytics-service/components/errors/acknowledge',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [API_TAG_PIPELINE_ERROR_COUNT],
    }),
  }),
  overrideExisting: false,
});

export const { usePipelineErrorCountQuery, useAckUnreadErrorsMutation } = injectedEndpoints;
