export const globalStyles = {
  global: {
    'html, body': {
      fontFamily: 'Inter, sans-serif',
    },
    html: {
      fontSize: '15px',
    },
  },
};
