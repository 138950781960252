import { ReactElement, useCallback, useState } from 'react';
import {
  chakra,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

interface TruncatedTextProps {
  children: string;
  className?: string;
  tooltipContent?: ReactElement;
  postfix?: string;
  maxBodyWidth?: string | number;
  variant?: 'paragraph' | 'label' | 'link';
  showCursorPointer?: boolean;
  showTooltipForcefully?: boolean;
}

const POPOVER_MAX_HEIGHT = '200px';

export const TruncatedText = chakra(
  ({
    children,
    className,
    tooltipContent,
    postfix,
    maxBodyWidth,
    variant,
    showCursorPointer,
    showTooltipForcefully,
  }: TruncatedTextProps) => {
    const [hasTextOverflow, setHasTextOverflow] = useState(false);

    const handleRefMount = useCallback((element: HTMLParagraphElement) => {
      if (!element) return;
      const { clientWidth, scrollWidth } = element;
      setHasTextOverflow(scrollWidth > clientWidth);
    }, []);

    return (
      <Popover trigger="hover" openDelay={50} closeDelay={50} placement="top">
        <PopoverTrigger>
          <Flex w="full" overflow="hidden">
            <Text
              isTruncated
              ref={handleRefMount}
              className={className}
              cursor={showCursorPointer ? 'pointer' : 'default'}
              variant={variant}
            >
              {children}
            </Text>
            {postfix && <Text>&nbsp;{postfix}</Text>}
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            p={0}
            w="fit-content"
            display={showTooltipForcefully || hasTextOverflow ? 'block' : 'none'}
          >
            <PopoverArrow color={rgba(COLORS.fullWhite, OPACITY[20])} />
            <PopoverBody
              p={2}
              display="flex"
              justifyContent="center"
              w="fit-content"
              maxWidth={maxBodyWidth}
              maxHeight={POPOVER_MAX_HEIGHT}
              wordBreak="break-word"
              overflowY="auto"
            >
              {tooltipContent || children}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  },
);
