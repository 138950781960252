import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'src/components/Notification/types';
import { modifyNotifications } from 'src/utils/notifications';

export type NotificationPayload = Omit<Notification, 'id' | 'timestamp'>;

interface InitialState {
  notifications: Notification[];
}

const notificationsSlice = createSlice({
  name: 'notifications',
  reducerPath: 'notifications',
  initialState: {
    notifications: [],
  } as InitialState,
  reducers: {
    showNotification: (state, action: PayloadAction<NotificationPayload>) => {
      const notification = { ...action.payload };
      state.notifications = modifyNotifications(notification, state.notifications);
    },

    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      );
    },
  },
  selectors: {
    selectNotifications: (state) => state.notifications,
  },
});

export default notificationsSlice;
