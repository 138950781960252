import { useCallback, useEffect, useRef, useState } from 'react';
import { chakra, CloseButton, Flex, shouldForwardProp, Text } from '@chakra-ui/react';
import { isValidMotionProp, motion, useAnimate } from 'framer-motion';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip/IconButtonWithTooltip';
import { RedirectIcon } from '../Icons/RedirectIcon';
import {
  UPDATE_ANIMATION_CONFIG,
  EXIT_ANIMATION_CONFIG,
  INITIAL_ANIMATION_CONFIG,
  MOUNT_ANIMATION_CONFIG,
  STATUS_COLOR_MAP,
  STATUS_ICON_MAP,
} from './constants';
import { Notification as NotificationType } from './types';
import { NOTIFICATION_TIMEOUT_MS } from 'src/constants/notifications';

interface NotificationBannerProps extends NotificationType {
  onClose: (id: string) => void;
  onOpenDialog: VoidFunction;
}

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export function NotificationBanner({
  id,
  message,
  status,
  timestamp,
  shouldTimeout,
  showTimestamp,
  onClose,
  onOpenDialog,
}: NotificationBannerProps) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const timestampRef = useRef<string>('');

  const [scope, animate] = useAnimate();

  const [color, borderColor] = STATUS_COLOR_MAP[status];
  const Icon = STATUS_ICON_MAP[status];
  const textId = `${id}-toast-${message}`;

  useEffect(() => {
    const el = document.getElementById(textId);
    if (el) {
      const hasOverflow = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
      setIsOverflowing(hasOverflow);
    }
  }, [textId]);

  useEffect(() => {
    if (shouldTimeout) {
      setTimeout(() => {
        onClose(id);
      }, NOTIFICATION_TIMEOUT_MS);
    }
  }, [id, shouldTimeout, onClose]);

  const triggerAnimation = useCallback(async () => {
    // animation when notification appears first time
    if (!timestampRef.current) {
      await animate(scope.current, MOUNT_ANIMATION_CONFIG, { type: 'tween', duration: 0.4 });
      timestampRef.current = timestamp;
    }

    // animation when notification is updated
    if (timestampRef.current && timestampRef.current !== timestamp) {
      await animate(scope.current, UPDATE_ANIMATION_CONFIG, { duration: 0.8 });
      timestampRef.current = timestamp;
    }
  }, [animate, scope, timestamp]);

  useEffect(() => {
    triggerAnimation();
  }, [triggerAnimation]);

  return (
    <ChakraBox ref={scope} layout initial={INITIAL_ANIMATION_CONFIG} exit={EXIT_ANIMATION_CONFIG}>
      <Flex
        mb={2}
        ml={4}
        px={4}
        py={2}
        w="470px"
        bgColor={color}
        border={`1px solid ${borderColor}`}
        borderRadius="20px"
        alignItems="center"
        data-cy="user-toast-notification"
      >
        <Icon fill="none" color={borderColor} mr={4} />
        <Text
          id={textId}
          mr={2}
          maxH="300px"
          whiteSpace="break-spaces"
          overflow="hidden"
          marginRight="auto"
          noOfLines={2}
        >
          {showTimestamp && `${timestamp}\n`}
          {message}
        </Text>
        {isOverflowing && (
          <IconButtonWithTooltip
            mr={2}
            icon={<RedirectIcon />}
            aria-label="Redirect"
            label="Show error details"
            fill="none"
            size="sm"
            variant="ghost"
            onClick={onOpenDialog}
          />
        )}
        <CloseButton onClick={() => onClose(id)} />
      </Flex>
    </ChakraBox>
  );
}
