import { DeleteSourceResponse } from '@common-types/site_manager/api/v1/site_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'src/components/Notification/hooks/useNotification';
import axios from 'src/lib/axios/axios';

export function useDeleteSourceByIdMutation() {
  const queryClient = useQueryClient();
  const { showSuccessNotification } = useNotification();

  return useMutation<{ data: DeleteSourceResponse }, unknown, string>({
    mutationFn: (sourceId) => axios.delete(`/v1/source/${sourceId}`),
    onSuccess: async () => {
      showSuccessNotification('Source successfully removed.');
      await queryClient.invalidateQueries({ queryKey: ['sources'] });
      await queryClient.invalidateQueries({ queryKey: ['list-sources'] });
    },
  });
}
