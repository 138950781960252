import {
  createContext,
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { doesStringsMatch } from 'src/utils/filter';
import { AutocompleteOptions } from './types';
import { DispatchStateAction } from 'src/types';

interface AutocompleteContextType {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  inputRef: RefObject<HTMLInputElement>;
  autocompleteOptions: AutocompleteOptions;
  setAutocompleteOptions: DispatchStateAction<AutocompleteOptions>;
  isRestrictedValue: boolean;
  setIsRestrictedValue: Dispatch<SetStateAction<boolean>>;
  filterAutocompleteOptions: (keyword: string) => void;
}

const AutocompleteContext = createContext({} as AutocompleteContextType);

export const useAutocompleteContext = () => useContext(AutocompleteContext);

interface AutocompleteContextProviderProps extends PropsWithChildren {
  showOptionsOnSearchTextOnly?: boolean;
}

export const AutocompleteContextProvider: React.FC<AutocompleteContextProviderProps> = ({
  showOptionsOnSearchTextOnly,
  children,
}) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>([]);
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOptions>([]);
  const [isRestrictedValue, setIsRestrictedValue] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const filterAutocompleteOptions = useCallback(
    (keyword: string) => {
      if (showOptionsOnSearchTextOnly && !keyword) {
        setFilteredOptions([]);
        return;
      }
      setFilteredOptions(autocompleteOptions.filter((option) => doesStringsMatch(option, keyword)));
    },
    [showOptionsOnSearchTextOnly, autocompleteOptions],
  );

  useEffect(() => {
    if (showOptionsOnSearchTextOnly && !inputRef.current?.value) {
      setFilteredOptions([]);
      return;
    }
    setFilteredOptions(autocompleteOptions);
  }, [showOptionsOnSearchTextOnly, autocompleteOptions]);

  const contextValue = useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
      inputRef,
      autocompleteOptions: filteredOptions,
      setAutocompleteOptions,
      filterAutocompleteOptions,
      isRestrictedValue,
      setIsRestrictedValue,
    }),
    [isOpen, onOpen, onClose, filteredOptions, filterAutocompleteOptions, isRestrictedValue],
  );

  return (
    <AutocompleteContext.Provider value={contextValue}>{children}</AutocompleteContext.Provider>
  );
};
