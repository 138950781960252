import { v4 as uuidv4 } from 'uuid';
import {
  GetUserNotificationsSummaryResponse,
  GetUserNotificationsSummaryResponse_CategorySummary,
  ListNotificationsResponse,
} from '@common-types/notification_service/api/v1/ns_api';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import invert from 'lodash/invert';
import startCase from 'lodash/startCase';
import { createFilterOption } from 'src/components/Filters/utils';
import { Notification } from 'src/components/Notification/types';
import {
  API_ERROR_MESSAGE_CHAR_LIMIT,
  RESPONSE_ERROR_MESSAGE_PATH,
  RESPONSE_ERROR_REASON_PATH,
} from 'src/constants/errors';
import { SOMETHING_WENT_WRONG } from 'src/constants/errorsMessages';
import {
  EMPTY_SEVERITY_FILTER_COUNTS,
  EMPTY_TYPE_FILTER_COUNTS,
  MAX_NOTIFICATIONS_COUNT,
  NOTIFICATION_FILTER_SUMMARY_KEY_MAP,
  NOTIFICATION_FILTER_TYPE_MAP,
} from 'src/constants/notifications';
import { snakeToTitleCase } from './text/snakeToTitleCase';

export const getAcknowledgedNotificationsData =
  (ids: string[]) => (oldData?: ListNotificationsResponse) => {
    if (!oldData) {
      return oldData;
    }

    const notifications = oldData.notifications.map((notification) => {
      if (ids.includes(notification.id)) {
        return { ...notification, ack: true };
      }

      return notification;
    });

    return { ...oldData, notifications };
  };

export const getNotificationFilterValues = (
  values: string[],
  additionalInfo: Record<string, number>,
) =>
  values.map((status) => ({
    ...createFilterOption(status, snakeToTitleCase(status)),
    additionalInfo: additionalInfo[status],
  }));

const getNotificationObjectKeys = (selectedValues: string[], keyMap: RecordString) =>
  selectedValues.length > 0 ? selectedValues.map((value) => keyMap[value]) : Object.values(keyMap);

type GetNotificationTypeFilterCountsParams = {
  notificationsSummary?: GetUserNotificationsSummaryResponse;
  selectedSeverityFilterValues: string[];
};

export const getNotificationTypeFilterCounts = ({
  notificationsSummary,
  selectedSeverityFilterValues,
}: GetNotificationTypeFilterCountsParams) => {
  if (!notificationsSummary) {
    return EMPTY_TYPE_FILTER_COUNTS;
  }

  const notificationSummaryKeys = getNotificationObjectKeys(
    selectedSeverityFilterValues,
    NOTIFICATION_FILTER_SUMMARY_KEY_MAP,
  );

  const typeFilterCounts = notificationSummaryKeys.reduce((acc, key) => {
    const notificationSummaryKey = key as keyof typeof notificationsSummary;

    const severitySummaryInfo = notificationsSummary[
      notificationSummaryKey
    ] as GetUserNotificationsSummaryResponse_CategorySummary;

    return {
      read: acc.read + severitySummaryInfo.readCount,
      unread: acc.unread + severitySummaryInfo.unreadCount,
    };
  }, EMPTY_TYPE_FILTER_COUNTS);

  return typeFilterCounts;
};

type GetNotificationSeverityFilterCountsParams = Omit<
  GetNotificationTypeFilterCountsParams,
  'selectedSeverityFilterValues'
> & {
  selectedTypeFilterValues: string[];
};

export const getNotificationSeverityFilterCounts = ({
  notificationsSummary,
  selectedTypeFilterValues,
}: GetNotificationSeverityFilterCountsParams) => {
  if (!notificationsSummary) {
    return EMPTY_SEVERITY_FILTER_COUNTS;
  }

  const notificationSummaryKeys = Object.values(NOTIFICATION_FILTER_SUMMARY_KEY_MAP);

  const severityFilterCounts = notificationSummaryKeys.reduce((acc, key) => {
    const notificationSummaryKey = key as keyof typeof notificationsSummary;

    const severitySummaryInfo = notificationsSummary[
      notificationSummaryKey
    ] as GetUserNotificationsSummaryResponse_CategorySummary;

    const notificationSeverityValueKeys = getNotificationObjectKeys(
      selectedTypeFilterValues,
      NOTIFICATION_FILTER_TYPE_MAP,
    );

    const severityCount = notificationSeverityValueKeys.reduce(
      (count, type) => count + severitySummaryInfo[type as keyof typeof severitySummaryInfo],
      0,
    );

    return {
      ...acc,
      [invert(NOTIFICATION_FILTER_SUMMARY_KEY_MAP)[notificationSummaryKey]]: severityCount,
    };
  }, EMPTY_SEVERITY_FILTER_COUNTS);

  return severityFilterCounts;
};

export const getNotificationTimestamp = (): string =>
  format(new Date().getTime(), 'MMM dd, yyyy HH:mm:ss');

const getClippedErrorMessage = (message: string): string =>
  (message.split('\n')?.[0] || '').substring(0, API_ERROR_MESSAGE_CHAR_LIMIT);

export const getApiErrorMessage = (error: unknown): string => {
  let message = SOMETHING_WENT_WRONG;
  const errorReason = get(error, RESPONSE_ERROR_REASON_PATH);
  const errorMessage = get(error, RESPONSE_ERROR_MESSAGE_PATH);
  const errorRootMessage = get(error, 'message');

  if (errorReason && errorMessage) {
    message = `${startCase(capitalize(errorReason))}: ${getClippedErrorMessage(errorMessage)}`;
  } else if (errorRootMessage) {
    message = getClippedErrorMessage(errorRootMessage);
  }
  return message;
};

function checkDuplicateNotification(
  notification: Pick<Notification, 'message' | 'status' | 'shouldForceDuplicate'>,
  notifications: Notification[],
) {
  if (notification.shouldForceDuplicate) {
    return false;
  }
  const duplicateNotification = notifications.find(
    ({ message, status }) => message === notification.message && status === notification.status,
  );
  return duplicateNotification?.id;
}

export function modifyNotifications(
  notification: Omit<Notification, 'id' | 'timestamp'>,
  notifications: Notification[],
) {
  const isDuplicateId = checkDuplicateNotification(notification, notifications);
  // handle duplicate notification
  if (isDuplicateId) {
    return notifications.map((item) => {
      if (item.id === isDuplicateId) {
        return { ...item, timestamp: getNotificationTimestamp() };
      }
      return item;
    });
  }

  const currentNotification = {
    ...notification,
    id: uuidv4(),
    timestamp: getNotificationTimestamp(),
  };
  const updatedNotifications = [...notifications, currentNotification];
  if (updatedNotifications.length > MAX_NOTIFICATIONS_COUNT) {
    updatedNotifications.splice(0, updatedNotifications.length - MAX_NOTIFICATIONS_COUNT);
  }
  return updatedNotifications;
}
