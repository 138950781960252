import { AutocompleteBox, AutocompleteProps } from './AutocompleteBox';
import { AutocompleteContextProvider } from './AutocompleteContext';

export function AutocompleteWrapper({
  children,
  showOptionsOnSearchTextOnly,
  ...restProps
}: AutocompleteProps) {
  return (
    <AutocompleteContextProvider showOptionsOnSearchTextOnly={showOptionsOnSearchTextOnly}>
      <AutocompleteBox {...restProps}>{children}</AutocompleteBox>
    </AutocompleteContextProvider>
  );
}
