import axios from 'src/lib/axios/axios';
import { Site } from '@common-types/site_manager/model/v1/site';
import { useCallback, useState } from 'react';
import { isNonEmptyArray } from 'src/utils/isNonEmptyArray';
import useOnMountEffect from './useOnMountEffect';

const EMPTY_SITES: Site[] = [];

function getPaginationParams(offset: number) {
  return {
    'pagination.offset': String(offset),
    'pagination.limit': '100',
    'pagination.ordering.fieldName': 'updated_at',
    'pagination.ordering.descending': true,
  };
}

export function useAllSitesQuery() {
  const [sites, setSites] = useState(EMPTY_SITES);
  const [error, setError] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(true);

  const getAllSites = useCallback(async () => {
    let offset = 0;
    let numOfSites = 0;
    const allSites = [];
    do {
      try {
        const result = await axios({ url: '/v1/sites', params: getPaginationParams(offset) });
        const response = result.data;
        numOfSites = response.pagination?.totalCount || 0;
        if (isNonEmptyArray(response.sites)) {
          allSites.push(...response.sites);
        }
        if (numOfSites === allSites.length) {
          setSites([...allSites]);
          setIsLoading(false);
        }
      } catch (apiError) {
        setError(apiError);
        setIsLoading(false);
      }

      offset += 100;
    } while (offset < numOfSites);
  }, []);

  useOnMountEffect(() => {
    getAllSites();
  });

  return { sites, isLoading, error, refetchSites: getAllSites };
}
