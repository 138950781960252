import { useCallback, useState } from 'react';
import { Portal, Text, useDisclosure, VStack } from '@chakra-ui/react';
import noop from 'lodash/noop';
import Dialog from '../Dialog/Dialog';
import { NotificationBanner } from './NotificationBanner';
import notificationsSlice from 'src/store/notifications';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { useNotification } from './hooks/useNotification';

const { selectNotifications } = notificationsSlice.selectors;

export function Notifications() {
  const [activeErrorMessage, setActiveErrorMessage] = useState('');

  const notifications = useSelector(selectNotifications);
  const { removeNotification } = useNotification();
  const { isOpen: isDialogOpen, onClose: onDialogClose, onOpen: onDialogOpen } = useDisclosure();

  const handleDialogClose = useCallback(() => {
    onDialogClose();
    setActiveErrorMessage('');
  }, [onDialogClose]);

  const closeNotification = useCallback(
    (id: string) => {
      removeNotification(id);
    },
    [removeNotification],
  );

  return (
    <Portal>
      <VStack position="absolute" bottom={4} gap={2} left={4} zIndex="popover">
        <AnimatePresence>
          {notifications.map((notification) => (
            <NotificationBanner
              key={notification.id}
              id={notification.id}
              status={notification.status}
              message={notification.message}
              timestamp={notification.timestamp}
              shouldTimeout={notification.shouldTimeout}
              showTimestamp={notification.showTimestamp}
              onClose={closeNotification}
              onOpenDialog={() => {
                setActiveErrorMessage(notification.message);
                onDialogOpen();
                closeNotification(notification.id);
              }}
            />
          ))}
        </AnimatePresence>
      </VStack>
      <Dialog
        shouldUseCustomZIndex
        actionLabel=""
        cancelLabel="Close"
        title="Error Details"
        type="error"
        isLoading={false}
        onActionClick={noop}
        isOpen={isDialogOpen}
        cancelOnly
        bodyContent={<Text>{activeErrorMessage}</Text>}
        onClose={handleDialogClose}
      />
    </Portal>
  );
}
