import { Outlet } from 'react-router';
import { AppMeta } from './components/AppMeta/AppMeta';
import { ErrorBoundary } from './components/Error/ErrorBoundary';
import { Notifications } from './components/Notification/Notifications';
import { RaisePrModal } from './components/RaisePrModal';
import { Chatbot } from './features/Chatbot';
import { useAppInit } from './hooks/useAppInit';
import SidebarLayout from './layouts/SidebarLayout';

function App() {
  useAppInit();

  return (
    <>
      <ErrorBoundary>
        <SidebarLayout>
          <AppMeta />
          <Outlet />
          <Notifications />
        </SidebarLayout>
      </ErrorBoundary>
      <Chatbot />
      <RaisePrModal />
    </>
  );
}

export default App;
