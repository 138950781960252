import { useLocation, useParams, useSearchParams } from 'react-router';
import { AcknowledgementState } from '@common-types/analytics_service/conf/v1/analytics-config';
import { PipelineDetailsTabs } from 'src/constants/pipelineTabs';
import { ROUTE_PATHS } from 'src/constants/routes';
import { useSite } from 'src/context/SiteProvider';
import { getTabIdFromView } from 'src/utils/pipelineDetailsTab';
import { isDevEnvironment } from 'src/utils/general';
import { ERROR_COUNT_POLL_INTERVAL_MS } from 'src/constants/errors';
import { usePipelineErrorCountQuery } from 'src/store/api/errorMonitoring/pipelineErrorCount';
import { isIgnorePipelineErrors } from 'src/utils/pipelineErrors';

export function useGetPipelineErrors() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { pipelineId } = useParams();
  const { siteId } = useSite();

  const pipelineDetailsTabId = getTabIdFromView(searchParams.get('view'));
  const isDisableRefetch =
    location.pathname === ROUTE_PATHS.monitoring.pipelineErrors ||
    (location.pathname ===
      ROUTE_PATHS.pipelines.pipelineDetails.replace(':pipelineId', pipelineId as string) &&
      pipelineDetailsTabId === PipelineDetailsTabs.Errors);

  const pollingInterval = isDisableRefetch || isDevEnvironment() ? 0 : ERROR_COUNT_POLL_INTERVAL_MS;
  const { currentData: errorsData, isLoading: isErrorsCountLoading } = usePipelineErrorCountQuery(
    { siteId, acknowledgeState: AcknowledgementState.UNACKNOWLEDGED },
    { skip: !siteId || isIgnorePipelineErrors(), skipPollingIfUnfocused: true, pollingInterval },
  );

  const { totalCount, pipelineErrorCount } = errorsData || {};

  return {
    errorsCount: isNaN(Number(totalCount)) ? totalCount : Number(totalCount),
    pipelineErrorCount,
    isErrorsCountLoading,
  };
}
