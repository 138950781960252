import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

const NOTIFICATIONS_POLLING_INTERVAL = 15 * 60 * 1000;

export const useNotificationsPoller = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      queryClient.invalidateQueries({ queryKey: ['notificationsSummary'] });
      queryClient.invalidateQueries({ queryKey: ['numOfUnreadNotifications'] });
    }, NOTIFICATIONS_POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [queryClient]);
};
