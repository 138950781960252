import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontFamily: 'JetBrains Mono',
  background: 'transparent',
});

export const Code = defineStyleConfig({
  baseStyle,
});
