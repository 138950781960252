import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  KeyboardEvent,
  MouseEvent,
  useCallback,
} from 'react';
import {
  Box,
  CloseButton,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  PopoverAnchor,
  PopoverContent,
  Portal,
  Text,
  useMergeRefs,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { noop } from 'src/utils/noop';
import { useAutocompleteContext } from './AutocompleteContext';

interface AutocompleteInputProps extends InputProps {
  onInputValueChange?: (value: string) => void;
  inputValue?: string;
  showEmptyOptionsMessage?: boolean;
  onOptionSelect?: (value: string) => void;
  showClearIcon?: boolean;
  onClear?: VoidFunction;
}

export const AutocompleteInput = forwardRef(function AutocompleteInput(
  {
    onInputValueChange = noop,
    inputValue = '',
    showEmptyOptionsMessage,
    showClearIcon,
    onOptionSelect,
    onKeyDown,
    onClear,
    ...inputProps
  }: AutocompleteInputProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) {
  const {
    onOpen,
    inputRef,
    autocompleteOptions,
    filterAutocompleteOptions,
    onClose,
    isRestrictedValue,
  } = useAutocompleteContext();

  const ref = useMergeRefs(forwardedRef, inputRef);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onOpen();
      filterAutocompleteOptions(value);
      onInputValueChange(value);
    },
    [onOpen, filterAutocompleteOptions, onInputValueChange],
  );

  const onAutocompleteOptionSelect = useCallback(
    (value: string) => (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      onInputValueChange(value);
      onOptionSelect?.(value);
      onClose();
    },
    [onClose, onInputValueChange, onOptionSelect],
  );

  const onInputBlur = useCallback(
    (event: FocusEvent) => {
      const isBlurredOnPopover = event.relatedTarget?.className.includes('popover');
      if (!isBlurredOnPopover) {
        onClose();
        filterAutocompleteOptions('');
        if (isRestrictedValue && !autocompleteOptions.includes(inputValue)) {
          onInputValueChange('');
        }
      }
    },
    [
      onClose,
      isRestrictedValue,
      autocompleteOptions,
      inputValue,
      onInputValueChange,
      filterAutocompleteOptions,
    ],
  );

  const onInputFocus = useCallback(() => {
    onOpen();
    filterAutocompleteOptions(inputValue);
  }, [onOpen, inputValue, filterAutocompleteOptions]);

  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown) {
        onClose();
        onKeyDown(event);
      }
    },
    [onClose, onKeyDown],
  );

  const handleClear = useCallback(() => {
    onClear?.();
    onInputValueChange('');
  }, [onClear, onInputValueChange]);

  return (
    <>
      <PopoverAnchor>
        <InputGroup>
          <Input
            {...inputProps}
            ref={ref}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            value={inputValue}
            onChange={onInputChange}
            onKeyDown={onEnter}
          />
          {showClearIcon && (
            <InputRightElement>
              <CloseButton disabled={!inputValue} onClick={handleClear} />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>
      <Portal>
        <Box zIndex="popover" position="relative">
          {(showEmptyOptionsMessage || autocompleteOptions.length) && (
            <PopoverContent w="full" p="0" m="0">
              <List
                w="full"
                bg={COLORS.darkerBlue}
                maxH="2xs"
                overflow="auto"
                p="0"
                m="0"
                borderRadius="md"
              >
                {autocompleteOptions.map((option, index) => (
                  <ListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    w="full"
                    m="0"
                    py="2"
                    cursor="pointer"
                    _hover={{ bg: rgba(COLORS.white, OPACITY[8]) }}
                    onClick={onAutocompleteOptionSelect(option)}
                  >
                    <Text p="0" px="4" h="10" display="flex" alignItems="center">
                      {option}
                    </Text>
                  </ListItem>
                ))}
                {showEmptyOptionsMessage && !autocompleteOptions.length && (
                  <ListItem w="full" m="0" py="2">
                    <Text p="0" px="4" h="10" display="flex" alignItems="center">
                      {inputValue
                        ? 'No options match the input query'
                        : 'Type something to get suggestions'}
                    </Text>
                  </ListItem>
                )}
              </List>
            </PopoverContent>
          )}
        </Box>
      </Portal>
    </>
  );
});
