import {
  AcknowledgementState,
  AlertEventState,
} from '@common-types/analytics_service/conf/v1/analytics-config';
import {
  AcknowledgeAlertEventsRequest,
  AcknowledgeAlertEventsResponse,
  ListAlertEventsResponse,
} from '@common-types/analytics_service/api/v1/analytics_service';
import { PaginationRequestParams } from 'src/types/PaginationRequest';
import apiSlice from 'src/store/api';
import { RTK_QUERY_DEFAULT_STALE_TIME } from 'src/constants/queries';
import { getApiTimeRangeSeconds } from 'src/utils/apiTimeRangeParams';
import { MAX_DATE_FILTER_VALUE } from 'src/constants/dateFilter';

const API_TAG_ALERT_EVENTS = 'alertEvents';
const API_TAG_UNREAD_ALERT_EVENTS_COUNT = 'numOfUnreadAlertEvents';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [API_TAG_ALERT_EVENTS, API_TAG_UNREAD_ALERT_EVENTS_COUNT],
});

interface AlertEventsQueryParams {
  siteIds: string[];
  alertStates?: AlertEventState[];
  timeValue: string;
  pagination: PaginationRequestParams;
}

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    alertEvents: build.query<ListAlertEventsResponse, AlertEventsQueryParams>({
      query: ({ siteIds, alertStates = [], timeValue, pagination }) => ({
        url: '/v1/analytics/alert-events',
        params: {
          siteIds,
          states: alertStates,
          ...getApiTimeRangeSeconds(timeValue),
          ...pagination,
          'pagination.ordering.fieldName': 'last_event_at',
          'pagination.ordering.descending': true,
        },
      }),
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_ALERT_EVENTS],
    }),

    unreadAlertEventsCount: build.query<number, { siteId?: string }>({
      query: ({ siteId }) => ({
        url: '/v1/analytics/alert-events/count',
        params: {
          siteId,
          acknowledgeState: AcknowledgementState.UNACKNOWLEDGED,
          ...getApiTimeRangeSeconds(MAX_DATE_FILTER_VALUE),
        },
      }),
      transformResponse: (response) => Number(response.alertEventCount || 0),
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_UNREAD_ALERT_EVENTS_COUNT],
    }),

    acknowledgeAlertEvents: build.mutation<
      AcknowledgeAlertEventsResponse,
      AcknowledgeAlertEventsRequest
    >({
      query: (data) => ({
        url: '/v1/analytics/alert-events/acknowledge',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [API_TAG_UNREAD_ALERT_EVENTS_COUNT],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAcknowledgeAlertEventsMutation,
  useAlertEventsQuery,
  useUnreadAlertEventsCountQuery,
} = injectedEndpoints;
