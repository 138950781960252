import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const baseStyle = definePartsStyle({
  field: {
    _hover: {
      boxShadow: 'none',
    },
    _focus: {
      boxShadow: 'none',
    },
    _dark: {
      fontSize: '0.875rem',
      color: 'white',
      bg: rgba(COLORS.link, OPACITY[4]),
      _hover: {
        borderColor: rgba(COLORS.fullWhite, OPACITY[80]),
      },
      _focus: {
        borderColor: 'highlightColor',
      },
      _focusVisible: {
        boxShadow: 'none',
        borderColor: 'highlightColor',
      },
      _invalid: {
        border: `1px solid ${COLORS.status.error}`,
        boxShadow: 'unset',
      },
      _disabled: {
        borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
        opacity: OPACITY[90],
      },
    },
  },
});

const variants = {
  search: {
    field: {
      fontSize: '14px',
      lineHeight: '20px',
      textColor: 'white',
      background: rgba(COLORS.darkBlue, OPACITY[50]),
      h: '40px',
      w: 'full',
      borderRadius: 'none',
      borderTopRadius: '4px',
      borderBottomWidth: '1px',
      borderColor: rgba(COLORS.fullWhite, OPACITY[30]),
      _placeholder: {
        textColor: rgba(COLORS.fullWhite, OPACITY[64]),
        fontStyle: 'italic',
        fontWeight: 200,
        fontSize: '14px',
      },
      _focus: {
        borderColor: 'white',
      },
    },
    addon: {
      borderColor: rgba(COLORS.fullWhite, OPACITY[64]),
    },
  },
};

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants,
});
