// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v3.21.12
// source: common/stubs/notification_service/model/v1/event.proto

/* eslint-disable */
import { type SubCategory } from "./event_type";

export const protobufPackage = "v1.event";

/** Next ID: 13 */
export interface Event {
  id: string;
  category: Event_Category;
  subCategory: SubCategory;
  createdAt: string | undefined;
  siteId: string;
  userId: string;
  jsonPayload: string;
  ackRequired: boolean;
  state: Event_EventState;
  ack: boolean;
  error: string;
  seriesId: string;
}

/** Next ID: 3 */
export enum Event_Category {
  SITE_EVENT = "SITE_EVENT",
  USER_EVENT = "USER_EVENT",
  /**
   * UPDATE_EVENT - Update the notification event itself. Used mainly for use cases like
   * acknowledging an event.
   */
  UPDATE_EVENT = "UPDATE_EVENT",
  ALERT_EVENT = "ALERT_EVENT",
  PDF_GEN_EVENT = "PDF_GEN_EVENT",
}

/** Next ID: 3 */
export enum Event_EventState {
  DEFAULT = "DEFAULT",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}
