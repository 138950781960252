import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const baseStyle = definePartsStyle({
  field: {
    _hover: {
      boxShadow: 'none',
    },
    _focus: {
      boxShadow: 'none',
    },
    _dark: {
      fontSize: '0.875rem',
      color: 'white',
      bg: rgba(COLORS.link, OPACITY[4]),
      _hover: {
        borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
      },
      _focus: {
        borderColor: 'highlightColor',
      },
      _focusVisible: {
        boxShadow: 'none',
        borderColor: 'highlightColor',
      },
      _invalid: {
        border: `1px solid ${COLORS.status.error}`,
        boxShadow: 'unset',
      },
      _disabled: {
        opacity: OPACITY[90],
        borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
        _hover: {
          borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
        },
        _focus: {
          borderColor: rgba(COLORS.fullWhite, OPACITY[8]),
        },
      },
    },
  },
});

export const NumberInput = defineMultiStyleConfig({
  baseStyle,
});
