import convert from 'convert';
import { getRiggedFactor } from './riggPercentageValue';
import { Buffer } from 'buffer';

const BYTES_IN_ONE_PB = 1_000_000_000_000_000;
const BYTES_IN_ONE_TB = 1_000_000_000_000;
const BYTES_IN_ONE_GB = 1_000_000_000;
const BYTES_IN_ONE_MB = 1_000_000;
const BYTES_IN_ONE_KB = 1_000;

const BYTES_MULTI_FACTOR_KEY = 'rigged_bytes_by_factor_of';
const NUMBER_MULTI_FACTOR_KEY = 'rigged_number_by_factor_of';

export const convertDataAmountWithUnitsFromBytes = (
  bytes: number,
  precision = 1,
): {
  value: string;
  unit: string;
} => {
  const riggedFactor = getRiggedFactor(BYTES_MULTI_FACTOR_KEY);
  const riggedBytes = bytes * riggedFactor;
  if (riggedBytes >= BYTES_IN_ONE_PB) {
    return {
      value: convert(riggedBytes, 'B').to('PB').toFixed(precision),
      unit: 'PB',
    };
  }
  if (riggedBytes >= BYTES_IN_ONE_TB) {
    return {
      value: convert(riggedBytes, 'B').to('TB').toFixed(precision),
      unit: 'TB',
    };
  }
  if (riggedBytes >= BYTES_IN_ONE_GB) {
    return {
      value: convert(riggedBytes, 'B').to('GB').toFixed(precision),
      unit: 'GB',
    };
  }
  if (riggedBytes >= BYTES_IN_ONE_MB) {
    return {
      value: convert(riggedBytes, 'B').to('MB').toFixed(precision),
      unit: 'MB',
    };
  }
  if (riggedBytes >= BYTES_IN_ONE_KB) {
    return {
      value: convert(riggedBytes, 'B').to('KB').toFixed(precision),
      unit: 'KB',
    };
  }
  return {
    value: String(riggedBytes.toFixed(precision)),
    unit: 'B',
  };
};

export const convertDataAmountInBytesToString = (dataAmountBytes: number, precision = 1) => {
  const { value, unit } = convertDataAmountWithUnitsFromBytes(dataAmountBytes, precision);
  return `${value} ${unit}`;
};

export const convertNumberToShortString = (number: number, precision = 1) => {
  const riggedFactor = getRiggedFactor(NUMBER_MULTI_FACTOR_KEY);
  const riggedNumber = number * riggedFactor;

  if (riggedNumber >= 1000000000) {
    return `${(riggedNumber / 1000000000).toFixed(precision)}B`;
  }

  if (riggedNumber >= 1000000) {
    return `${(riggedNumber / 1000000).toFixed(precision)}M`;
  }

  if (riggedNumber >= 1000) {
    return `${(riggedNumber / 1000).toFixed(precision)}K`;
  }

  return riggedNumber.toFixed(precision);
};

export const convertNumberToFixedDecimals = (number: number, precision = 1) =>
  Number(number.toFixed(precision));

export const appendPercentageToNumber = (number: number, precision = 1) =>
  `${convertNumberToFixedDecimals(number, precision)}%`;

export const removePercentageFromNumber = (percentValue: string) =>
  Number(percentValue.replace('%', ''));

export function convertStringToBase64(inputStr: string): string {
  return Buffer.from(inputStr, 'utf-8').toString('base64');
}

export function convertBase64ToString(inputStr: string): string {
  return Buffer.from(inputStr, 'base64').toString('utf-8');
}
