export const FIXED_OPTIONS_LIST_HEIGHT_PIXELS = 400;

export enum FilterViewName {
  Dashboard = 'dashboard',
  Notifications = 'notifications',
  SiteLogPatterns = 'siteLogPatterns',
  SiteAnalytics = 'siteAnalytics',
  Destinations = 'destinations',
  Monitoring = 'monitoring',
  Pipelines = 'pipelines',
  PipelineAnalytics = 'pipelineAnalytics',
  PipelineErrors = 'pipelineErrors',
  PipelineOverview = 'pipelineOverview',
  Sources = 'sources',
  SourceDetails = 'sourceDetails',
  Hydration = 'hydration',
  PipelineDataInsightsOverview = 'pipelineDataInsightsOverview',
  PipelineDataInsightsOverviewDate = 'pipelineDataInsightsOverviewDate',
  PipelineDataInsightsPatterns = 'pipelineDataInsightsPatterns',
  PipelineDataInsightsPatternsDate = 'pipelineDataInsightsPatternsDate',
  TimeFilter = 'timefilter',
  Analytics = 'analytics',
  PullRequests = 'pullRequests',
  SiteFiles = 'siteFiles',
}
