import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { DispatchStateAction } from 'src/types';
import { doesStringsMatch } from 'src/utils/filter';
import { AutocompleteInput, AutocompleteWrapper } from '../Autocomplete';
import { SearchSelectItem } from './types';

interface SearchSelectInputProps {
  placeholder?: string;
  options?: SearchSelectItem[];
  restrictValueFromOptionsOnly?: boolean;
  setSelectedOptions: DispatchStateAction<string[]>;
}

export function SearchSelectInput({
  placeholder = 'Search Here',
  options = [],
  restrictValueFromOptionsOnly,
  setSelectedOptions,
}: SearchSelectInputProps) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const onOptionSelect = useCallback(
    (value: string) => {
      const optionValue = options.find(({ label }) => label === value)?.value;
      if (optionValue) {
        setSelectedOptions([optionValue]);
      }
    },
    [options, setSelectedOptions],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (inputValue && event.code === 'Enter') {
        const values = options
          .filter((option) => doesStringsMatch(option.label, inputValue))
          .map((pipeline) => pipeline.value);
        setSelectedOptions(values);
      }
    },
    [inputValue, options, setSelectedOptions],
  );

  const onClear = useCallback(() => {
    setSelectedOptions([]);
  }, [setSelectedOptions]);

  const displayOptions = useMemo(() => options.map(({ label }) => label), [options]);

  return (
    <AutocompleteWrapper
      options={displayOptions}
      showOptionsOnSearchTextOnly
      restrictValueFromOptionsOnly={restrictValueFromOptionsOnly}
    >
      <AutocompleteInput
        showClearIcon
        type="text"
        textOverflow="ellipsis"
        showEmptyOptionsMessage
        placeholder={placeholder}
        inputValue={inputValue}
        onInputValueChange={handleChange}
        onOptionSelect={onOptionSelect}
        onKeyDown={!restrictValueFromOptionsOnly ? onKeyDown : undefined}
        onClear={onClear}
      />
    </AutocompleteWrapper>
  );
}
