import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  formErrorAnatomy.keys,
);

const baseStyleText = defineStyle({
  fontSize: '14px',
  fontWeight: 300,
  _dark: {
    color: 'label',
  },
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
});

export const FormError = defineMultiStyleConfig({
  baseStyle,
});
