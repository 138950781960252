import {
  Avatar,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { useAuthStoreContext } from 'src/context/AuthProvider/AuthStoreContext';
import { useLogout } from 'src/hooks/useLogout';
import { noop } from 'src/utils/noop';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip/IconButtonWithTooltip';
import { ObservoLogoNoText } from '../Icons/ObservoLogoNoText';
import { LogOutIcon } from 'src/components/Icons/LogOutIcon';

interface AppErrorModalProps {
  header: string;
  body: string;
  showReload?: boolean;
}

function AppErrorModal({ header, body, showReload }: AppErrorModalProps) {
  const onLogout = useLogout();
  const { user } = useAuthStoreContext();
  const { name = '', picture } = user ?? {};

  return (
    <ChakraModal motionPreset="none" isOpen onClose={noop} isCentered>
      <ModalOverlay backgroundColor="transparent" />
      <ModalContent minW="800px">
        <ModalBody p="0">
          <Flex
            flexDirection="column"
            bg={rgba(COLORS.darkPurple, OPACITY[16])}
            boxShadow={`2px 2px 4px ${rgba(COLORS.black, OPACITY[25])}`}
            w="800px"
            px={16}
            pt={10}
          >
            <VStack w="full" gap={6} mb={8}>
              <Flex justifyContent="space-between" alignItems="center" w="full">
                <ObservoLogoNoText h={8} w="auto" />
                <HStack gap={2} justifyContent="stretch" alignItems="center" pl={1}>
                  <Avatar
                    name={name}
                    src={picture}
                    size="sm"
                    w="40px"
                    h="40px"
                    referrerPolicy="no-referrer"
                  />
                  <IconButtonWithTooltip
                    label="Logout"
                    fill="none"
                    onClick={onLogout}
                    size="md"
                    variant="ghost"
                    aria-label="Search database"
                    icon={<LogOutIcon />}
                    fontSize="24px"
                  />
                </HStack>
              </Flex>
              <VStack justifyContent="start" alignItems="start" w="full" gap={6}>
                <Heading variant="title-3">{header}</Heading>
                <Text fontSize="16px">{body}</Text>
                {showReload && (
                  <Button variant="outline" onClick={() => window.location.reload()}>
                    Reload
                  </Button>
                )}
              </VStack>
              <Divider />
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}
export default AppErrorModal;
